<template>
    <Fragment>
        <v-dialog v-model="internalOpen" max-width="700" persistent>
            <div style="background-color: #FFFFFF; padding: 0 1em 2em; position: relative;">
                <div class="py-4" style="width: 100%; background-color: inherit; position: sticky; top: 0; z-index: 10;">
                    <v-btn 
                        style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                        class="px-4 py-4"
                        @click.stop="cerrarModal" 
                        text
                        rounded
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                </div>
                <div class="px-1 mt-1">
                    <v-textarea 
                        v-model="comentario" 
                        label="Comentario" 
                        placeholder="Comentario"
                        outlined 
                        light
                        rows="4"
                        no-resize
                    />
                    <div class="d-flex justify-center align-center" style="gap: 16px">
                        <v-btn 
                            class="flex-grow-1 flex-shrink-1" 
                            color="secondary" 
                            @click.stop="cerrarModal"
                        >
                            Cancelar
                        </v-btn>
                        <v-btn 
                            class="flex-grow-1 flex-shrink-1" 
                            :disabled="!comentario" 
                            color="primary" 
                            @click.stop="confirmarObservacion"
                        >
                            Observar
                        </v-btn>
                    </div>
                </div>
            </div>
        </v-dialog>
        <ConfirmationModalComponent 
            :is-open="modalConfirmacion"
            description="¿Desea observar esta oferta?"
            :is-loading="observacionLoadable.isLoading"
            @confirm="observarOferta"
            @cancel="modalConfirmacion = false"
        />
    </Fragment>
</template>
<script>
import { createLoadable, toggleLoadable, setLoadableResponse, isResponseSuccesful } from '@/utils/loadable';
import { ConfirmationModalComponent } from '@/components/utils';
import { isNil } from 'lodash';

export default {
    name: 'ModalObservarOferta',
    components: { ConfirmationModalComponent },
    emits: ['on-visibility-change', 'on-observar', 'on-etapa-no-valida'],
    props: {
        isOpen: { type: Boolean },
        idOferta: { type: Number },
    },
    data: () =>({
        internalOpen: false,
        comentario: null,
        modalConfirmacion: false,
        observacionLoadable: createLoadable(null),
    }),
    methods: {
        cerrarModal() {
            this.internalOpen = false;
            this.comentario = null;
        },
        confirmarObservacion() {
            if (this.comentario.length > 255) {
                this.pushAppMessage({ type: 'error', message: 'La observación no debe exceder los 255 caracteres.' });

                return;
            }

            this.internalOpen = false;
            this.modalConfirmacion = true;
        },
        async observarOferta() {
            toggleLoadable(this.observacionLoadable);
            const payload = {
                comentario: this.comentario,
            }
            const { data } = await this.services.ConvocatoriaServices.observarOferta(this.idOferta, payload);
            setLoadableResponse(this.observacionLoadable, data, { showAlertOnSuccess: true, skipOnSuccess: true });
            this.modalConfirmacion = false;
            this.cerrarModal();

            if (isResponseSuccesful(data)) {
                this.$emit('on-observar')
            } else {
                if (data.error.code === 'ETAPA_NO_VALIDA') this.$emit('on-etapa-no-valida')
            }
        },
    },
    watch: {
        isOpen(value) {
            if (isNil(value)) return;

            this.internalOpen = value;
        },
        internalOpen(value) {
            this.$emit('on-visibility-change', value);
        },
    },
}
</script>
