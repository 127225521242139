<template>
    <v-container>
        <div class="d-flex align-center flex-wrap" style="gap: 16px">
            <NavButton />
            <h2 class="text-uppercase d-inline-block">Aprobacion convocatorias</h2>
            <v-chip v-if="ofertasConvocatoria.data?.convocatoria" label class="font-weight-bold">
                <v-icon class="mr-1" style="font-size: 22px !important;">{{ obtenerIconoEstadoConvocatoria(ofertasConvocatoria.data.convocatoria.estado) }}</v-icon>
                {{ ofertasConvocatoria.data.convocatoria.estado.nombre  }}
            </v-chip>
        </div>
        <DataTableComponent
            :headers="headers"
            :items="ofertas"
            class="mt-4"
            :show_loading="ofertasConvocatoria.isLoading"
            :total_registros="total"
            v-models:pagina="paginaActual"
            v-models:select="registrosPorPagina"
            @paginar="manejarPaginacionRegistros"
        >
            <template v-slot:item.created_at="{ item }">
                {{ formatDate(item.created_at) }}
            </template>
            <template v-slot:item.estado="{ item }">
                <v-chip 
                    :color="obtenerColorEstado(item.EstadoParticipacionProveedor.id)" 
                    :text-color="obtenerColorTextoEstado(item.EstadoParticipacionProveedor.id)"
                    class="d-inline-block mx-auto"
                    style="width: 150px !important;"
                    label
                >
                    {{ item.EstadoParticipacionProveedor.nombre }}
                </v-chip>
            </template>
            <template v-slot:item.acciones="{item}">
                <div class="d-flex align-center justify-center" style="gap: 16px">
                    <v-btn
                        color="secondary" 
                        icon 
                        :disabled="item.EstadoParticipacionProveedor.id === 3 || !convocatoriaEnEtapaEvaluacion" 
                        @click.stop="confirmarAprobacionOferta(item)"
                    >
                        <v-icon>mdi-check</v-icon>
                    </v-btn>
                    <v-btn 
                        color="secondary" icon 
                        :disabled="item.EstadoParticipacionProveedor.id === 3 || !(convocatoriaEnEtapaEvaluacion || convocatoriaEnEtapaRecepcion)" 
                        @click.stop="abrirModalObservarOferta(item)"
                    >
                        <v-icon>mdi-eye</v-icon>
                    </v-btn>
                    <v-btn 
                        v-if="item.observaciones.length > 0" 
                        color="secondary" 
                        icon 
                        @click.stop="abrirModalVerObservacion(item)"
                    >
                        <v-icon>mdi-alert-box</v-icon>
                    </v-btn>
                    <v-btn v-if="item.adjunto" color="secondary" icon @click.stop="visualizarAdjunto(item)">
                        <v-icon>mdi-file-pdf-box</v-icon>
                    </v-btn>
                </div>
            </template>
        </DataTableComponent>
        <ConfirmationModalComponent 
            :is-open="modalConfirmacionAprobacion"
            description="¿Desea aprobar esta oferta?"
            :is-loading="aprobacionOferta.isLoading"
            @confirm="aprobarOferta"
            @cancel="modalConfirmacionAprobacion = false"
        />
        <ModalObservarOferta 
            :is-open="modalObservarOfertaAbierta"
            :idOferta="ofertaActiva?.id"
            @on-visibility-change="cerrarModalObservarOferta"
            @on-observar="cargarOfertasConvocatoria"
            @on-etapa-no-valida="cargarOfertasConvocatoria"
        />
        <PdfModal
            :isOpen="modalVisualizacionAdjuntoAbierta" 
            :source-loadable="visualizacionAdjunto" 
            @on-visibility-change="manejarVisibilidadModalVisualizacionAdjunto"
            filename="adjunto-oferta"
        />
        <ModalObservacionParticipacion
            :is-open="modalVerObservacionAbierta"
            :oferta="ofertaActiva"
            @on-visibility-change="cerrarModalVerObservacion"
        />
    </v-container>
</template>
<script>
import { 
    createPageable, 
    createLoadable, 
    toggleLoadable, 
    setLoadableResponse, 
    togglePageable, 
    setPageableResponse, 
    isResponseSuccesful 
} from '@/utils/loadable';
import ModalObservacionParticipacion from './components/ModalObservacionParticipacion.vue';
import { ConfirmationModalComponent, NavButton } from '@/components/utils';
import ModalObservarOferta from './components/ModalObservarOferta.vue';
import DataTableComponent from '@/components/DataTableComponent.vue';
import PdfModal from '@/components/PdfModal.vue';
import { formatDate } from '@/utils/datetime';

export default {
    name: 'CmAprobacionConvocatorias',
    components: { DataTableComponent, ConfirmationModalComponent, ModalObservarOferta, PdfModal, ModalObservacionParticipacion, NavButton },
    data: () => ({
        idConvocatoria: null,
        ofertasConvocatoria: createPageable(null, 10),
        paginaActual: 1,
        registrosPorPagina: 10,
        headers: [
            { align: 'center', sortable: false, text: 'Fecha creación', value: 'created_at' },
            { align: 'center', sortable: false, text: 'Estado', value: 'estado' },
            { align: 'center', sortable: false, text: 'Proveedor', value: 'Proveedor.nombre_comercial' },
            { align: 'center', sortable: false, text: 'Acciones', value: 'acciones' },
        ],
        // Acciones
        ofertaActiva: null,
        modalConfirmacionAprobacion: false,
        modalObservarOfertaAbierta: false,
        modalVisualizacionAdjuntoAbierta: false,
        modalVerObservacionAbierta: false,
        aprobacionOferta: createLoadable(null),
        visualizacionAdjunto: createLoadable(null),
    }),
    computed: {
        total() {
            return this.ofertasConvocatoria.pagination.total_rows;
        },
        ofertas() {
            return this.ofertasConvocatoria.data?.ofertas ?? [];
        },
        convocatoriaEnEtapaRecepcion() {
            return this.ofertasConvocatoria.data?.convocatoria.estado.codigo === 'RECEPCION';
        },
        convocatoriaEnEtapaEvaluacion() {
            return this.ofertasConvocatoria.data?.convocatoria.estado.codigo === 'EVALUACION';
        },
    },
    methods: {
        async cargarOfertasConvocatoria() {
            const filtros = {
                pagination: true,
                per_page: this.registrosPorPagina,
                page: this.paginaActual,
            };

            togglePageable(this.ofertasConvocatoria);
            const { data, headers } = await this.services.ConvocatoriaServices.cargarOfertasConvocatoria(this.idConvocatoria, filtros);
            setPageableResponse(this.ofertasConvocatoria, data, headers);
        },
        manejarPaginacionRegistros(paginacion) {
            const { pagina, cantidad_por_pagina } = paginacion;
            this.paginaActual = pagina;
            this.registrosPorPagina = cantidad_por_pagina;
            this.cargarOfertasConvocatoria();
        },
        confirmarAprobacionOferta(oferta) {
            this.ofertaActiva = oferta;
            this.modalConfirmacionAprobacion = true;
        },
        async aprobarOferta() {
            toggleLoadable(this.aprobacionOferta);
            const { data } = await this.services.ConvocatoriaServices.aprobarOferta(this.ofertaActiva.id);
            this.modalConfirmacionAprobacion = false;
            setLoadableResponse(this.aprobacionOferta, data, { showAlertOnSuccess: true, skipOnSuccess: true });

            if (isResponseSuccesful(data)) {
                this.cargarOfertasConvocatoria();
            } else {
                if (data.error.code === 'ETAPA_NO_VALIDA') this.cargarOfertasConvocatoria();
            }
        },
        abrirModalObservarOferta(oferta) {
            this.ofertaActiva = oferta;
            this.modalObservarOfertaAbierta = true;
        },
        cerrarModalObservarOferta(visible) {
            this.modalObservarOfertaAbierta = visible;
        },
        async visualizarAdjunto(oferta) {
            this.modalVisualizacionAdjuntoAbierta = true;
            toggleLoadable(this.visualizacionAdjunto);
            const { data } = await this.services.ConvocatoriaServices.visualizarAdjuntoParticipacion(oferta.id);
            setLoadableResponse(this.visualizacionAdjunto, data, { isFile: true });
        },
        manejarVisibilidadModalVisualizacionAdjunto(visible) {
            this.modalVisualizacionAdjuntoAbierta = visible;
        },
        obtenerColorEstado(estado) {
            switch(estado) {
                case 1:
                    return '#345288';
                case 3:
                    return 'success'
                case 4:
                    return '#ffff9e';
                default:
                    return 'default';
            }
        },
        obtenerColorTextoEstado(estado) {
            return [1, 3].includes(estado) ? '#FFFFFF !important' : '#000000';
        },
        abrirModalVerObservacion(oferta) {
            this.ofertaActiva = oferta;
            this.modalVerObservacionAbierta = true;
        },
        cerrarModalVerObservacion(visible) {
            this.modalVerObservacionAbierta = visible;
        },
        formatDate,
        obtenerIconoEstadoConvocatoria(estadoConvocatoria) {
            const estado = estadoConvocatoria.codigo;
            switch (estado) {
                case 'RECEPCION':
                    return 'mdi-email-arrow-left';
                case 'EVALUACION':
                    return 'mdi-progress-pencil';
                case 'RESULTADOS':
                    return 'mdi-order-bool-descending-variant';
                default: 
                    return 'mdi-timer-off';
            };
        },
    },
    created() {
        this.idConvocatoria = this.$route.params.id_convocatoria;
        this.cargarOfertasConvocatoria();
    },
}
</script>