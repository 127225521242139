<template>
    <v-dialog v-model="internalOpen" max-width="700" persistent>
        <div style="background-color: #FFFFFF; padding: 0 1em 2em; position: relative;">
            <div class="pt-4 pb-2" style="width: 100%; background-color: inherit; position: sticky; top: 0; z-index: 10;">
                <v-btn 
                    style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                    class="px-4 py-4"
                    @click.stop="cerrarModal" 
                    text
                    rounded
                >
                    <v-icon>mdi-window-close</v-icon>
                </v-btn>
            </div>
            <div class="px-1">
                <template v-if="observacion">
                    <h4>Comentario:</h4>
                    <v-textarea 
                        v-model="observacion"
                        class="mt-2"
                        readonly
                        rows="4"
                        hide-details
                        outlined
                    />
                </template>
                <p v-else>Esta oferta no tiene observaciones.</p>
                <v-btn color="secondary" class="d-block mx-auto mt-4" @click.stop="cerrarModal">Cerrar</v-btn>
            </div>
        </div>
    </v-dialog>
</template>
<script>
import { isNil } from 'lodash';

export default {
    name: 'ModalObservacionParticipacion',
    emits: ['on-visibility-change'],
    props: {
        isOpen: { type: Boolean },
        oferta: { type: Object },
    },
    data: () => ({
        internalOpen: false,
    }),
    computed: {
        observacion() {
            return this.oferta?.observaciones.length > 0 
                ? this.oferta.observaciones[0].comentario
                : null;
        },
    },
    methods: {
        cerrarModal() {
            this.internalOpen = false;
        },
    },
    watch: {
        isOpen(value) {
            if (isNil(value)) return;

            this.internalOpen = value;
        },
        internalOpen(value) {
            this.$emit('on-visibility-change', value);
        },
    },
}
</script>